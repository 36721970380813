import { LoadingButton } from '@mui/lab'
import { Box, Chip, Divider, List, ListItem, ListItemText, Paper, Stack, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService'
import { EmailToNameString } from 'src/Utils/helperFunc'
import { ChipColor, dateTimeOptions } from 'src/config/constants'
import { InspectionStatusDto, InspectionStatusType, InspectionStatusTypeString } from 'src/dtos/Checklists/AttractionInspections/InspectionStatus.dto'
import SearchIcon from '@mui/icons-material/Search';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'

function InspectionsStatus() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchTerm, setSearchTerm] = useState(searchParams.get("searchTerm") ?? "")
    const [searchInput, setSearchInput] = useState(searchTerm)
    const [messageItem, setMessageItem] = useState<MessageItem>({})

    const inspectionStatusQuery = useQuery({
        queryKey: ["StatusOfInspections", searchTerm],
        queryFn: async () => {
            return await InspectionChecklistResponseService.GetStatusOfInspections(searchTerm)
        }
    })

    useEffect(() => {
        if(inspectionStatusQuery.isError) {
            setMessageItem({error: inspectionStatusQuery.error})
        }
    }, [inspectionStatusQuery.error, inspectionStatusQuery.isError])

    const updateSearchTerm = (newSearch: string) => {
        setSearchTerm(newSearch)
        searchParams.set("searchTerm", newSearch)
        setSearchParams(searchParams)
    }


    //types/status and order
    const InspectionTypes = [
        InspectionStatusType.Failed,
        InspectionStatusType.Outstanding,
        InspectionStatusType.InProgress,
        InspectionStatusType.Passed
    ]

    const InspectionsList = ({inspections, type}:{inspections: InspectionStatusDto[], type: InspectionStatusType}) => {
        if(inspections.length === 0)
            return <></>

        const InspectionListItem = ({inspection}:{inspection: InspectionStatusDto}) => {
            return (
                <ListItem alignItems="flex-start" sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6">{inspection.inspectionChecklistLabel}</Typography>
                    <ListItemText secondary={inspection.completedBy} />
                    <Stack direction={"row"} spacing={1}>
                        {inspection.startedTime && <ListItemText secondary={`Started: ${new Date(inspection.startedTime).toLocaleDateString(undefined, dateTimeOptions)} | ${EmailToNameString(inspection.startedBy??"")}`} />}
                        {inspection.completedTime && <ListItemText secondary={`Completed: ${new Date(inspection.completedTime).toLocaleDateString(undefined, dateTimeOptions)} | ${EmailToNameString(inspection.completedBy??"")}`} />}
                    </Stack>
                    {inspection.expiry && <ListItemText secondary={`Expiry: ${new Date(inspection.expiry).toLocaleDateString(undefined, dateTimeOptions)}`} />}
                </ListItem>
            )
        }

        const chipColour: ChipColor = type === InspectionStatusType.Failed ? "error" :
            type === InspectionStatusType.Passed ? "success" :
            type === InspectionStatusType.InProgress ? "primary" :
            "default"

        return (
            <>
                
                <Paper sx={{margin:"0.5rem"}}>
                    <span style={{display:"flex", justifyContent:"center", paddingTop:"1rem"}}>
                        <Chip 
                            label={`${InspectionStatusTypeString[type]} Inspections`}
                            variant="filled"
                            color={chipColour}
                            sx={{fontSize:"1.5rem", padding:"1rem"}}
                        />
                    </span>
                    <List>
                        {inspections.map((inspection, index) => (
                            <>
                                <InspectionListItem
                                    key={inspection.inspectionChecklistId}
                                    inspection={inspection}
                                />
                                {index + 1 < inspections.length && (
                                    <Divider component="li"/>
                                )}
                            </>
                        ))}
                    </List>
                </Paper>
            </>

        )
    }

    return (
        <Box sx={{paddingTop:"1rem"}}>
            <Stack direction={"row"} spacing={1} sx={{display:"felx", justifyContent:"center"}}>
                <TextField
                    key={"searchTermInput"}
                    onBlur={() => {updateSearchTerm(searchInput);}}
                    onKeyDown={e => {
                        if(e.key === "Enter"){
                            updateSearchTerm(searchInput)
                        }
                    }}
                    id="searchTermInput" 
                    name="searchTermInput" 
                    label="Search" 
                    variant='outlined' 
                    size='small' 
                    value={searchInput} 
                    onChange={e => setSearchInput(e.target.value)} 
                />
                <LoadingButton variant={"contained"}  loading={inspectionStatusQuery.isLoading || inspectionStatusQuery.isFetching} onClick={() => inspectionStatusQuery.refetch()}><SearchIcon/></LoadingButton>
            </Stack>
            {inspectionStatusQuery.data && InspectionTypes.map((type, index) => (
                <InspectionsList
                    key={index}
                    inspections={inspectionStatusQuery.data.filter(inspection => inspection.status === type)}
                    type={type}
                />
            ))}
            <ErrorHandlingSnackbar messageItem={messageItem} />
        </Box>
    )
}

export default InspectionsStatus