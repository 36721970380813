import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { AttachmentDto } from 'src/dtos/AttachmentDto.dto'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import AttachFileIcon from '@mui/icons-material/AttachFile';

type Props = {
    currentAttachments: AttachmentDto[]
}
function Attachments({currentAttachments}: Props) {
  return (
    <>
        <Stack direction={"row"} spacing={1}>
            <Button>
                <Stack sx={{display:"flex", alignItems:"center"}}>
                    <CameraAltIcon fontSize='large'/>
                    <Typography variant='caption'>Take Photo</Typography>
                </Stack>
            </Button>
            <Button>
                <Stack sx={{display:"flex", alignItems:"center"}}>
                    <RadioButtonCheckedIcon fontSize='large'/>
                    <Typography variant='caption'>Take Video</Typography>
                </Stack>
            </Button>
            <Button>
                <Stack sx={{display:"flex", alignItems:"center"}}>
                    <AttachFileIcon fontSize='large'/>   
                    <Typography variant='caption'>Choose file</Typography>
                </Stack>
            </Button>
        </Stack>
        <div>
            all attachments
        </div>
    </>
  )
}

export default Attachments