import { Stack, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import React from 'react'
import EditableLabel from 'src/components/EditableLabel/EditableLabel'
import { JournalEntryDto } from 'src/dtos/Checklists/AttractionInspections/Journal.dto'
import * as yup from 'yup'

export const ServiceTaskValidationSchema = yup.object({
    label: yup.string().required("Entry Title is a required field"),
    type: yup.number().required("Entry Type is a required field"),
})

type Props = {
    formik: FormikProps<JournalEntryDto>
}
function ServiceTaskEntry({formik}:Props) {
  return (
    <>
        <Stack>
            <Typography variant='h6'>Action Required</Typography>
            <EditableLabel
                value={formik.values.description ?? ""}
                setValue={(newValue: string) => formik.setFieldValue("description", newValue)}
                defaultValue='Describe the action required to complete/resolve the task'
            />
            {formik.values.description && formik.values.description.length > 0 && (
                <>
                    <Typography variant='h6'>Action Taken</Typography>
                    <EditableLabel
                        value={formik.values.resolutionDescription ?? ""}
                        setValue={(newValue: string) => formik.setFieldValue("resolutionDescription", newValue)}
                        defaultValue='Describe the action taken to complete/resolve the task'
                    />    
                </>
            )}
        </Stack>
    </>
  )
}

export default ServiceTaskEntry