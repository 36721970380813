import { Autocomplete, Paper, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService';
import InspectionChecklistService from 'src/Services/Checklists/Inspections/InspectionChecklistService';
import InspectionChecklistVersionService from 'src/Services/Checklists/Inspections/InspectionChecklistVersionService';
import FinalSignOffSection from 'src/components/FormSections/FinalSignOffSection/FinalSignOffSection';
import FormSection from 'src/components/FormSections/FormSection/FormSection';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import MaintenanceBottomNavigation from 'src/components/common/MaintenanceBottomNavigation';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { ComponentResponseDto, InspectionChecklistResponseDto, InspectionChecklistSectionResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto';
import { ChildEntity } from 'src/dtos/EntityChild.dto';
import { InspectionChecklist, InspectionChecklistSection, InspectionChecklistVersion } from 'src/dtos/Checklists/MaintenanceChecklist.dto';
import { Status } from 'src/dtos/Statuses';


function Inspection() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [finalSignOffImage, setFinalSignOffImage] = useState<string | null>(null);
    
    const [checklistAnswers, setChecklistAnswers] = useState<InspectionChecklistResponseDto>(InspectionChecklistResponseService.GetDefaultValues());
    const [checklistVersion, setChecklistVersion] = useState<InspectionChecklistVersion>(InspectionChecklistVersionService.GetDefaultVersionValues());
    const [checklist, setChecklist] = useState<InspectionChecklist>(InspectionChecklistService.GetDefaultValues())
    const [sectionVisibility, setSectionVisibility] = useState<boolean[]>(() => checklistVersion.sections.map(section => sectionVisible(section)))

    const [taggedOutComponents, setTaggedOutComponents] = useState<ChildEntity[]>([]);
    const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined});


    const {responseId} = useParams();


    const onFinalSignOffCapture = (image: string) => {
        setFinalSignOffImage(image);
    };    

    const save = (values: any) => {

    }

    useEffect(() => {
        const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/; //check if a string is a guid

        const fetchInspection = async () => {
            try {
                if(responseId !== undefined && guidRegex.test(responseId)){
                    const responseData = await InspectionChecklistResponseService.Get(responseId);
                    const versionData = await InspectionChecklistVersionService.GetInspection(responseData.inspectionChecklistVersionId);
                    const checklistData = await InspectionChecklistService.Get(versionData.inspectionChecklistId)
                    setChecklistAnswers(responseData)
                    setChecklist(checklistData)
                    setChecklistVersion(versionData)
                }
                
            } catch (error: any) {
                setMessageItem({error: error})
            }
        }
        
        fetchInspection();
        

    }, [responseId])

    const handleUserSelectedCompChange = (value: any) => {
        if(formik.values.selectedComponentsResponse && checklistVersion.userSelectedComponent){
            const newUserSelectedComp = formik.values.selectedComponentsResponse;
            newUserSelectedComp.children = value;
            formik.setFieldValue("selectedComponentsResponse", newUserSelectedComp)
            newUserSelectedComp.id && InspectionChecklistResponseService.UpdateComponentsResponse(newUserSelectedComp.id, newUserSelectedComp)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        initialValues: checklistAnswers,
        onSubmit: (values) => {
            save(values);
        }
    });

    const sectionVisible = (section: InspectionChecklistSection) : boolean => {
        if(section.userSelectedComponents === true && formik.values.selectedComponentsResponse && section.repeatingComponentSet){
            return formik.values.selectedComponentsResponse.children.some(e => e.relatedComponent.id === section.repeatingComponentSet?.id);
        } else {
            return true;
        }
    }

    useEffect(() => {
        const newSectionVisiblity = checklistVersion.sections.map(section => sectionVisible(section));
        setSectionVisibility(newSectionVisiblity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.selectedComponentsResponse?.children, checklistVersion.sections])

    //determines if the questions in the provided section are editable.
    const questionsEditable = (sectionIndex: number) : true | string  => {
        const lastSectionStatus = sectionIndex - 1 >= 0 && formik.values.sectionResponses[sectionIndex-1].status;

        if(formik.values.status === Status.Passed || formik.values.status === Status.Failed){
            return "Cannot edit a completed inspection"
        }

        if(checklistVersion.forceWorkflow === false){
            return true
        }

        if(lastSectionStatus === Status.Passed || lastSectionStatus === Status.Failed || lastSectionStatus === Status.ComponentFailed || lastSectionStatus === false){
            return true
        } else {
            return "Must complete previous section before proceeding"
        }
    };

    const updateSectionAnswers = (sectionIndex: number, sectionValues: InspectionChecklistSectionResponseDto, sectionStatus: Status) => {
        const updatedSectionAnswers = [...formik.values.sectionResponses];
        sectionValues.status = sectionStatus
        updatedSectionAnswers[sectionIndex] = sectionValues;

        //component failed doesnt prevent the inspection from passing
        const inspectionPassed = Boolean(updatedSectionAnswers.filter(sectionResponse => sectionResponse.status === Status.Passed || sectionResponse.status === Status.ComponentFailed).length === updatedSectionAnswers.length)
        const inspectionFailed = Boolean(updatedSectionAnswers.filter(sectionResponse => sectionResponse.status === Status.Failed).length > 0)

        const inspectionStatus: Status = inspectionPassed ? Status.Passed : inspectionFailed ? Status.Failed : Status.Inprogress;
        formik.setValues({...formik.values, status: inspectionStatus, sectionResponses: updatedSectionAnswers})
        
    }

    const submitInspection = async () => {
        try {
            var values = formik.values;
            values.completedTime = new Date();
            
            //cannot be submitted as in progress, should fail the inspection
            if(values.status === Status.Inprogress){
                values.status = Status.Failed
            }
            const response = await InspectionChecklistResponseService.Submit(formik.values.id, values)
            if(response.status >= 200 || response.status <= 299){
                setMessageItem({successMessage: "Inspection Submitted."})
            }
        } catch (error: any){
            setMessageItem({error: error})
        }
    }

    const userSelectedOptions = (userSelectedComponent: ChildEntity) : ComponentResponseDto => {
        const componentResponse: ComponentResponseDto = {
            label: userSelectedComponent.label,
            relatedComponent: userSelectedComponent,
            children: userSelectedComponent.children.map((selectedComponentChild) => {
                const newChild : ComponentResponseDto = {
                    label: selectedComponentChild.label,
                    relatedComponent: selectedComponentChild,
                    children: []
                };
                return newChild;
            }
            )
        }

        return componentResponse;
    }

    // if(checklistAnswers.completedTime !== undefined && checklistAnswers.completedTime !== null){
    //     return (
    //         <>
    //             <Typography>Inspection already completed!</Typography>
    //         </>
    //     )
    // }
    return (
        <>
            <div style={{ paddingBottom: "5rem" }}>
                <form onSubmit={formik.handleSubmit}>
                <Typography variant='h5' sx={{display: "flex", justifyContent: "center", padding: "1rem"}}>{checklist.label}</Typography>
                {checklistVersion.userSelectedComponent && (
                    <div style={{padding:"1rem"}}>
                
                    <Paper sx={{padding:"1rem"}}>
                        <QuestionTitle title={`Which ${checklistVersion.userSelectedComponent.label} are you inspecting during this inspection?`} required/>
                        <Autocomplete
                            id={`userSelecteditems_${checklistVersion.userSelectedComponent.label}`}
                            multiple
                            options={userSelectedOptions(checklistVersion.userSelectedComponent).children}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    size="small"
                                    label={checklistVersion.userSelectedComponent?.label}
                                />
                            )}
                            sx={{width: "20rem"}}
                            onChange={(_, value) => handleUserSelectedCompChange(value)}
                            value={
                                formik.values.selectedComponentsResponse?.children ? formik.values.selectedComponentsResponse.children : []
                            }
                        />
                    </Paper>
                </div>  
                )}
                {checklistVersion.sections.map((section, sectionIndex) => (
                    <Fragment key={sectionIndex}>
                        {sectionVisibility[sectionIndex] && (
                            <FormSection
                                key={sectionIndex}
                                sectionIndex={sectionIndex}
                                sectionContent={section}
                                initialValues={checklistAnswers.sectionResponses[sectionIndex]}
                                percentageToPass={checklistVersion.sections[sectionIndex].percentageToPass}
                                updateSectionAnswers={(sectionValues: InspectionChecklistSectionResponseDto, sectionStatus: Status) => updateSectionAnswers(sectionIndex, sectionValues, sectionStatus)}
                                questionsEditable={() => questionsEditable(sectionIndex)}
                                taggedOutComponents={taggedOutComponents}
                                setTaggedOutComponents={setTaggedOutComponents}
                                demoMode={false}
                                setMessageItem={setMessageItem}
                                selectedComponentsResponse={formik.values.selectedComponentsResponse}
                            />
                        )}
                    </Fragment>
                    
                ))}
                
                <FinalSignOffSection
                    checklistName={checklist.label}
                    onCapture={onFinalSignOffCapture}
                    checklistStatus={formik.values.status}
                    submitInspection={submitInspection}
                />
                
                </form>
            </div>

            <ErrorHandlingSnackbar messageItem={messageItem} alertSx={{marginBottom:"5rem"}}/>
            <MaintenanceBottomNavigation />
        </>
    );
}

export default Inspection