import axios, { AxiosResponse } from "axios";
import { GetBearer } from "src/Utils/authUtils";
import { CompetencyDocumentDto, LearningDocumentPointResponse, LearningDocumentResponseDto, LearningDocumentResponseStatus, LearningDocumentSubpointResponse, LearningDocumentTopicResponse, StartDocumentRequestDto } from "src/dtos/Training/LearningDocumentResponse.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/LearningDocumentResponse";
const debug = process.env.REACT_APP_DEBUG;

export default class LearningDocumentResponseService {
    static GetDefaultValues() {
        const defaultVals: LearningDocumentResponseDto = {
            id: "00000000-0000-0000-0000-000000000000",
            learningDocumentVersionId: "00000000-0000-0000-0000-000000000000",
            status: LearningDocumentResponseStatus.InProgress,
            attendanceSheetResponseId: "00000000-0000-0000-0000-000000000000",
            attendanceSheetResponse: {
                id: "00000000-0000-0000-0000-000000000000",
                attendees: []
            },
            topicResponses: [
                {
                    id: 0,
                    status: LearningDocumentResponseStatus.Unanswered,
                    commentary: "",
                    subtopicResponses: [
                        {
                            id: 0,
                            status: LearningDocumentResponseStatus.Unanswered,
                            pointResponses: [
                                {
                                    id: 0,
                                    status: LearningDocumentResponseStatus.Unanswered,
                                    checked: false,
                                    subpointResponses: [
                                        {
                                            id: 0,
                                            status: LearningDocumentResponseStatus.Unanswered,
                                            checked: false,
                                        }
                                    ] 
                                }
                            ]
                        }
                    ]
                }
            ]
        }
        return defaultVals;
    }

    static async GetInitialValues(versionId: string) {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocumentResponseDto> = await axios.get<LearningDocumentResponseDto>(`${apiBaseUrl}/GetInitialValues/${versionId}`, {
                headers: {
                    Authorization: bearer
                },
            });
            return response.data;
        }
        catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async Get(id: string) {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocumentResponseDto> = await axios.get<LearningDocumentResponseDto>(`${apiBaseUrl}/Get/${id}`, {
                headers: {
                    Authorization: bearer
                },
            });
            return response.data;
        }
        catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async GetTrainableCompetencyDocuments(teamMemberId: string): Promise<CompetencyDocumentDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<CompetencyDocumentDto[]> = await axios.get<CompetencyDocumentDto[]>(`${apiBaseUrl}/GetTrainableCompetencyDocuments/${teamMemberId}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async StartDocument(startRequest: StartDocumentRequestDto){
        try{
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocumentResponseDto> = await axios.post<LearningDocumentResponseDto>(`${apiBaseUrl}/StartDocument`,
            startRequest,
            {
                headers: {
                    Authorization: bearer
                }
            });
            return response;
        }
        catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async AddAssessor(id: string, microsoftId: string){
        try{
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocumentResponseDto> = await axios.put<LearningDocumentResponseDto>(`${apiBaseUrl}/AddAssessor/${id}`,
            null,
            {
                headers: {
                    Authorization: bearer
                },
                params: {
                    microsoftId: microsoftId
                }
            });
            return response;
        }
        catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async UpdateSubpoint(subpointResponseId: number, updateSubpointResponse: LearningDocumentSubpointResponse){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocumentSubpointResponse> = await axios.put<LearningDocumentSubpointResponse>(`${apiBaseUrl}/UpdateSubpoint/${subpointResponseId}`, updateSubpointResponse, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdatePoint(pointResponseId: number, updatePointResponse: LearningDocumentPointResponse){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocumentPointResponse> = await axios.put<LearningDocumentPointResponse>(`${apiBaseUrl}/UpdatePoint/${pointResponseId}`, updatePointResponse, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateTopic(topicResponseId: number, updateTopicResponse: LearningDocumentTopicResponse){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocumentTopicResponse> = await axios.put<LearningDocumentTopicResponse>(`${apiBaseUrl}/UpdateTopic/${topicResponseId}`, updateTopicResponse, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateResponse(id: string, updateResponse: LearningDocumentResponseDto){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocumentResponseDto> = await axios.put<LearningDocumentResponseDto>(`${apiBaseUrl}/UpdateResponse/${id}`, updateResponse, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async SubmitDocument(id: string){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocumentResponseDto> = await axios.put<LearningDocumentResponseDto>(`${apiBaseUrl}/SubmitDocument/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async MarkUnsigned(id: string){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocumentResponseDto> = await axios.put<LearningDocumentResponseDto>(`${apiBaseUrl}/MarkUnsigned/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async DeleteResponse(id: string) {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.delete<AxiosResponse>(`${apiBaseUrl}/DeleteResponse/${id}`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    id: id
                }
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

}