import { Box, IconButton, Stack, Typography, TypographyProps } from '@mui/material'
import React, { useRef, useState } from 'react'
import colorConfigs from 'src/config/colorConfigs'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    value: string
    defaultValue: string
    setValue?: (newValue: string) => void
    onBlur?: () => void
    typographyProps?: TypographyProps
}
function EditableLabel({value, defaultValue, typographyProps, onBlur, setValue}: Props) {
    const [isEditing, setIsEditing] = useState(false);
    const [displayValue, setDisplayValue] = useState(value || defaultValue); // Local state for display value
    const [isCloseClicked, setIsCloseClicked] = useState(false);
    const editableRef = useRef<HTMLDivElement>(null); 
    
    const handleFocus = () => {
        setIsEditing(true);

        if (displayValue === defaultValue) {
            setDisplayValue(""); // Clear the value when starting to edit
        }
    }

    const handleSave = () => {
        setIsEditing(false); // Stop editing mode
        const currentValue = editableRef.current?.textContent ?? "";
        setDisplayValue(currentValue); // Save changes
        setValue && setValue(currentValue); //Update parent

    }

    const handleBlur = () => {

        if (isCloseClicked) {
            setIsCloseClicked(false); // Reset state
            return;
        }

        handleSave();
        
        // If the content is empty, reset to defaultValue
        const currentValue = editableRef.current?.textContent ?? ""
        if (currentValue.trim().length === 0) {
            setDisplayValue(defaultValue);
        }
        
        onBlur && onBlur();
    }

    const handleCloseClick = (event: React.MouseEvent) => {

        setIsEditing(false); // Stop editing mode
        setDisplayValue(value); // Revert to original value
        // Ensure the editableRef content is also reverted
        if (editableRef.current) {
            editableRef.current.textContent = value;
            editableRef.current.blur();
        }
    }

    const handleDoneClick = () => {
        handleSave()
    }

    const handlekeyPress = (e: KeyboardEvent) => {
        if(e.key === "Enter"){
            e.preventDefault();
            handleSave();
            editableRef.current && editableRef.current.blur();
        }
    }

    return (
        <Stack direction={"row"} spacing={1}>
            <Typography 
                ref={editableRef}
                suppressContentEditableWarning={true} 
                contentEditable={true}
                component={"div"}
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...typographyProps}
                onKeyDown={handlekeyPress}
                sx={{
                    backgroundColor: 'transparent',
                    transition: 'background-color 0.3s ease',
                    ...(!isEditing && {
                        '&:hover': {
                            backgroundColor: colorConfigs.utils.hoverBg,
                        }
                    }),
                    width:"100%",
                    padding:"4px",
                    paddingLeft: "6px",
                    borderRadius:"5px",
                    fontStyle: displayValue === defaultValue ? 'italic' : 'normal', // Conditionally set italics
                }}
                
            >
                {displayValue}
            </Typography>
            <Box>
                {isEditing && (
                    <Stack direction={"row"} sx={{paddingTop:"5px", display:"flex", justifyContent:"end"}} spacing={1}>
                        <IconButton size="small" onMouseDown={handleDoneClick}>
                            <DoneIcon/>
                        </IconButton>
                        <IconButton size="small" onMouseDown={handleCloseClick}>
                            <CloseIcon/>
                        </IconButton>
                        
                    </Stack>
                )}
            </Box>
        </Stack>
    )
}

export default EditableLabel