import axios, { AxiosResponse } from "axios";
import { GetBearer } from "src/Utils/authUtils";
import { JournalEntryDto, JournalEntryStatus, JournalEntryType } from "src/dtos/Checklists/AttractionInspections/Journal.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/AttractionJournal";
const debug = process.env.REACT_APP_DEBUG;

export default class AttractionJournalService {
    static GetDefaultEntryValues() {
        const defaultVals: JournalEntryDto = {
            id: "00000000-0000-0000-0000-000000000000",
            label: "",
            type: JournalEntryType.serviceTask,
            status: JournalEntryStatus.outstanding,
            description: "",
            resolutionDescription: "",
            attachments: [],
            comments: [],
            enabled: true
        }

        return defaultVals
    }

    static async GetEntry(id: string): Promise<JournalEntryDto> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<JournalEntryDto> = await axios.get<JournalEntryDto>(`${apiBaseUrl}/getEntry/${id}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}
