import { Button, Chip, Divider, List, ListItem, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import MaintenanceBottomNavigation from 'src/components/common/MaintenanceBottomNavigation'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditableLabel from 'src/components/EditableLabel/EditableLabel';

function Journal() {
    function FreeFormItem({heading, label}: {heading: string, label: string, }) {
        return(
            <>
                <Divider component="li" />
                <ListItem alignItems="flex-start">
                    <Stack sx={{width: "100%"}} spacing={1}>
                        <div>
                            <Typography variant='h6'>{heading}</Typography>
                            <Typography>{label}</Typography>
                        </div>
                        <Stack direction={"row"} spacing={1}>
                            <Typography variant="caption" sx={{color: "#333", fontWeight: "600"}}>{`John Smith`}</Typography>
                            <Typography variant="caption">{`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`}</Typography>
                        </Stack>
                    </Stack>
                </ListItem>
                <Divider component="li" />
            </>
        )
    }

    function RepairItemOutstanding(){
        return (
            <>
                <Divider component="li" />
                <ListItem alignItems="flex-start">
                    <Stack sx={{width: "100%"}} spacing={1}>
                        <div>
                            <Chip label="Outstanding" color='warning' size='small'/>
                            <Typography variant='h6'>{"Repair: Structure"}</Typography>
                            <Typography>{"What Failed: Center theming not attached"}</Typography>
                            <Typography>{"Action Required: Center theming resecured"}</Typography>
                        </div>
                        <Stack direction={"row"} spacing={1}>
                            <Typography variant="caption" sx={{color: "#333", fontWeight: "600"}}>{`Sally Smith`}</Typography>
                            <Typography variant="caption">{`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`}</Typography>
                        </Stack>
                    </Stack>
                </ListItem>
                <Divider component="li" />
            </>
        )
    }
    function RepairItemResolved(){
        return (
            <>
                <Divider component="li" />
                <ListItem alignItems="flex-start">
                    <Stack sx={{width: "100%"}} spacing={1}>
                        <div>
                            <Chip label="Resolved" color='success' size='small'/>
                            <Typography variant='h6'>{"Repair: Gondala"}</Typography>
                            <Typography>{"What Failed: Strut cracked"}</Typography>
                            <Typography>{"Action Required: Strut replacement"}</Typography>
                        </div>
                        <Stack direction={"row"} spacing={1}>
                            <Typography variant="caption" sx={{color: "#333", fontWeight: "600"}}>{`Sally Smith`}</Typography>
                            <Typography variant="caption">{`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`}</Typography>
                        </Stack>
                        <div style={{paddingLeft: "2rem"}}>
                            <Divider component="li" light />

                            <Stack>
                                <Typography>{"Action Completed: Replaced the strut"}</Typography>
                                <Stack direction={"row"} spacing={1}>
                                    <Typography variant="caption" sx={{color: "#333", fontWeight: "600"}}>{`Bob Anderson`}</Typography>
                                    <Typography variant="caption">{`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`}</Typography>
                                </Stack>

                            </Stack>
                            
                            
                        </div>
                    </Stack>
                    
                </ListItem>
                <Divider component="li" />
            </>
        )
    }

    function ItemsList() {
        return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            
            <FreeFormItem
                heading='Example title'
                label='Some example service notes - free form'
            />
            <FreeFormItem
                heading='Example title 2'
                label='Some other example service notes- free form'
            />
            <RepairItemOutstanding/>
            <RepairItemResolved/>
        
        </List>
        )
    }

    function NewEntryMenu() {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        }

        const handleClose = () => {
            setAnchorEl(null);
        }

        return (
            <span style={{display:"flex", justifyContent: "center"}}>
                <Button
                    id="add-entry-button"
                    aria-controls={open ? 'add-entry-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Add New Entry
                </Button>
                <Menu
                    id="add-entry-menu"
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                >
                    <MenuItem>
                        Free Form
                    </MenuItem>
                    <MenuItem>
                        Service Note
                    </MenuItem>
                    <MenuItem>
                        E-Stop Triggered
                    </MenuItem>
                </Menu>
            </span>
        )
    }

    const [testValue, setTestValue] = useState("test")
  return (
    <>
        <ItemsList/>
        <NewEntryMenu/>

        <div style={{width:"30rem", padding:"1rem"}}>
            <EditableLabel
                value={testValue}
                defaultValue='New Entry Title'
                setValue={(newVal: string) => setTestValue(newVal)}
                typographyProps={{
                    variant:"h5"
                }}
            />
        </div>

        <div style={{paddingTop: "5rem"}}>
            <MaintenanceBottomNavigation />
        </div>
        
    </>
    
  )
}

export default Journal