export enum Status{ 
    Passed,
    Failed, 
    Inprogress,
    Unanswered,
    NA,
    Published,
    Draft,
    Outdated,
    ComponentFailed
}

export const StatusString : string[] = [
    "Passed",
    "Failed",
    "In Progress",
    "Unanswered",
    "N/A",
    "Published",
    "Draft",
    "Outdated",
    "Component Failed"
] 