import { AttachmentDto } from "src/dtos/AttachmentDto.dto";
import { BaseModel } from "src/dtos/BaseModel.dto";

export interface JournalEntryDto extends BaseModel {
    id?: string;
    label: string;
    type: JournalEntryType;
    status: JournalEntryStatus;
    description?: string; //also used to describe action required
    resolutionDescription?: string;
    resolvedOn?: Date;
    resolvedBy?: string;
    attachments: AttachmentDto[];
    comments: CommentDto[];
}

export enum JournalEntryType {
    serviceTask,
    issueFaultRecord,
    outOfService
}
export const JournalEntryTypeString: string[] = [
    "Service Task",
    "Issue/Fault Record",
    "Out of Service"
]

export enum JournalEntryStatus {
    NA,
    outstanding,
    completed
}
export const JournalEntryStatusString: string[] = [
    "N/A",
    "Outstanding",
    "Completed"
]

export interface CommentDto extends BaseModel {
    id?: number;
    attachments: AttachmentDto[];
    description?: string;
}