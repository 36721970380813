import axios, { AxiosResponse } from "axios";
import { GetBearer } from "src/Utils/authUtils";
import { ComponentResponseDto, InspectionChecklistQuestionResponseDto, InspectionChecklistResponseDto, InspectionChecklistSectionResponseDto } from "src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto";
import { Status } from "src/dtos/Statuses";
import { ListParameters, SortDirection } from "../../ListParameters";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { InspectionStatusDto } from "src/dtos/Checklists/AttractionInspections/InspectionStatus.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/InspectionChecklistResponse";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class InspectionChecklistResponseService {
    static GetDefaultValues(){
        const defaultVals: InspectionChecklistResponseDto = {
            id: "00000000-0000-0000-0000-000000000000",
            inspectionChecklistVersionId: "00000000-0000-0000-0000-000000000000",
            status: Status.Inprogress,
            sectionResponses: [
                {
                    id: 1,
                    status: Status.Unanswered,
                    questionResponses: [
                        {
                            id: 1,
                            status: Status.Unanswered,
                            answer: null,
                            issueResolved: null,
                            failureCause: ""
                        }
                    ]
                }
            ]
        }
        return defaultVals;
    }
    static async GetNew(versionId: string){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<InspectionChecklistResponseDto> = await axios.get<InspectionChecklistResponseDto>(`${apiBaseUrl}/GetNew/${versionId}`, {
                headers: {
                    Authorization: bearer
                },
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async StartInspection(inspectionId: string){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<InspectionChecklistResponseDto> = await axios.post<InspectionChecklistResponseDto>(`${apiBaseUrl}/StartInspection/${inspectionId}`, 
            null,
            {
                headers: {
                    Authorization: bearer
                },
            });
            return response;
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async GetActiveInspection(inspectionId: string){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<InspectionChecklistResponseDto> = await axios.get<InspectionChecklistResponseDto>(`${apiBaseUrl}/GetActiveInspection/${inspectionId}`, 
            {
                headers: {
                    Authorization: bearer
                },
            });
            return response;
        } catch (error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async GetCompletedInspections(listParam?: ListParameters, searchTerm?: string){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetCompletedInspections`, {
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,              
                },
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error;
        }
    }

    static async GetStatusOfInspections(searchTerm?: string): Promise<InspectionStatusDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<InspectionStatusDto[]> = await axios.get<InspectionStatusDto[]>(`${apiBaseUrl}/GetStatusOfInspections`, {
                params: {
                    searchTerm: searchTerm,              
                },
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Get(id: string){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<InspectionChecklistResponseDto> = await axios.get<InspectionChecklistResponseDto>(`${apiBaseUrl}/Get/${id}`, {
                headers: {
                    Authorization: bearer
                },
            });
            return response.data;
        } catch(error: any) {
            debug && console.log(error);
            throw error;
        }
    }

    static async UpdateQuestion(questionId: number, newQuestionResponse: InspectionChecklistQuestionResponseDto){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<InspectionChecklistQuestionResponseDto> = await axios.put<InspectionChecklistQuestionResponseDto>(`${apiBaseUrl}/UpdateQuestion`, newQuestionResponse, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    questionId: questionId
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateSection(sectionId: number, newSectionResponse: InspectionChecklistSectionResponseDto){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<InspectionChecklistSectionResponseDto> = await axios.put<InspectionChecklistSectionResponseDto>(`${apiBaseUrl}/UpdateSection`, newSectionResponse, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    sectionId: sectionId
                },
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }
    
    static async UpdateComponentsResponse(selectedComponentId: number, updatedSelectedComponentsResponse: ComponentResponseDto){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<ComponentResponseDto> = await axios.put<ComponentResponseDto>(`${apiBaseUrl}/UpdateComponentsResponse`, updatedSelectedComponentsResponse, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    selectedComponentId: selectedComponentId
                }
            });
            return response;
        } catch(error: any){
            debug && console.log(error)
            throw error;
        }
    }

    static async Submit(id: string, newChecklistResponse: InspectionChecklistResponseDto) {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Submit/${id}`, newChecklistResponse, {
                headers: {
                    Authorization: bearer
                },
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }


}