export enum InspectionStatusType {
    Passed,
    Failed, 
    InProgress,
    Outstanding
}

export const InspectionStatusTypeString: string[] = [
    "Passed",
    "Failed",
    "In Progress",
    "Outstanding"
]

export interface InspectionStatusDto {
    inspectionChecklistId: string;
    inspectionChecklistLabel: string;
    status: InspectionStatusType;
    expiry?: Date;
    startedTime?: Date;
    startedBy?: string;
    completedTime?: Date;
    completedBy?: string;
}