/* eslint-disable @typescript-eslint/no-unused-vars */

import { Autocomplete, Paper, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InspectionChecklistResponseService from "src/Services/Checklists/Inspections/ChecklistResponseService";
import InspectionChecklistService from "src/Services/Checklists/Inspections/InspectionChecklistService";
import InspectionChecklistVersionService from "src/Services/Checklists/Inspections/InspectionChecklistVersionService";
import FinalSignOffSection from "src/components/FormSections/FinalSignOffSection/FinalSignOffSection";
import FormSection from "src/components/FormSections/FormSection/FormSection";
import QuestionTitle from "src/components/QuestionTitle/QuestionTitle";
import MaintenanceBottomNavigation from "src/components/common/MaintenanceBottomNavigation";
import ErrorHandlingSnackbar, { MessageItem } from "src/components/errorHandlingSnackbar";
import {
    ChildEntity,
    InspectionChecklist,
    QuestionType,
    InspectionChecklistVersion,
    InspectionChecklistSection,
} from "src/dtos/AwTypes";
import { ComponentResponseDto, InspectionChecklistResponseDto, InspectionChecklistSectionResponseDto } from "src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto";
import { Status } from "src/dtos/Statuses";

function DemoQuestions() {
    const [finalSignOffImage, setFinalSignOffImage] = useState<string | null>(null);
    
    const [checklistAnswers, setChecklistAnswers] = useState<InspectionChecklistResponseDto>(InspectionChecklistResponseService.GetDefaultValues());
    const [checklistVersion, setChecklistVersion] = useState<InspectionChecklistVersion>(InspectionChecklistVersionService.GetDefaultVersionValues());
    const [checklist, setChecklist] = useState<InspectionChecklist>(InspectionChecklistService.GetDefaultValues())
    const [sectionVisibility, setSectionVisibility] = useState<boolean[]>(() => checklistVersion.sections.map(section => sectionVisible(section)))

    const [taggedOutComponents, setTaggedOutComponents] = useState<ChildEntity[]>([]);
    const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined});

    
    const {id} = useParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/; //check if a string is a guid
    

    const onFinalSignOffCapture = (image: string) => {
        setFinalSignOffImage(image);
    };    

    const save = (values: any) => {

    }

    useEffect(() => {
        const fetchInspection = async () => {
            try {
                if(id !== undefined && guidRegex.test(id)){
                    const data = await InspectionChecklistVersionService.GetInspection(id);
                    const inspectionChecklistId = data.inspectionChecklistId;
                    const checklistData = await InspectionChecklistService.Get(inspectionChecklistId)
                    setChecklist(checklistData)
                    setChecklistVersion(data)
                }
                
            } catch (error: any) {
                console.log(error)
                //setMessageItem({error: error})
            }
        }
        const fetchAnswers = async () => {
            try {
                if(id !== undefined && guidRegex.test(id)){
                    const data = await InspectionChecklistResponseService.GetNew(id);
                    setChecklistAnswers(data)
                }
            } catch (error: any) {
                console.log(error)
                //setMessageItem({error: error})
            }
        }

        fetchInspection();
        fetchAnswers();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        initialValues: checklistAnswers,
        onSubmit: (values) => {
            save(values);
        }
    });

    

    const updateSectionAnswers = (sectionIndex: number, sectionValues: InspectionChecklistSectionResponseDto, sectionStatus: Status) => {
        const updatedSectionAnswers = [...formik.values.sectionResponses];
        sectionValues.status = sectionStatus
        updatedSectionAnswers[sectionIndex] = sectionValues;
        formik.setValues({...formik.values, sectionResponses: updatedSectionAnswers})
        console.log(checklistVersion.sections[sectionIndex].label, sectionStatus)
    }

    const handleUserSelectedCompChange = (value: any) => {
        if(formik.values.selectedComponentsResponse){
            const newUserSelectedComp = formik.values.selectedComponentsResponse;
            newUserSelectedComp.children = value;
            formik.setFieldValue("selectedComponentsResponse", newUserSelectedComp)
        }
    }

    const sectionVisible = (section: InspectionChecklistSection) : boolean => {
        if(section.userSelectedComponents === true && formik.values.selectedComponentsResponse && section.repeatingComponentSet){
            return formik.values.selectedComponentsResponse.children.some(e => e.relatedComponent.id === section.repeatingComponentSet?.id);
        } else {
            return true;
        }
    }


    useEffect(() => {
        const newSectionVisiblity = checklistVersion.sections.map(section => sectionVisible(section));
        setSectionVisibility(newSectionVisiblity)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.selectedComponentsResponse?.children, checklistVersion.sections])

    const userSelectedOptions = (userSelectedComponent: ChildEntity) : ComponentResponseDto => {
        const componentResponse: ComponentResponseDto = {
            label: userSelectedComponent.label,
            relatedComponent: userSelectedComponent,
            children: userSelectedComponent.children.map((selectedComponentChild) => {
                const newChild : ComponentResponseDto = {
                    label: selectedComponentChild.label,
                    relatedComponent: selectedComponentChild,
                    children: []
                };
                return newChild;
            }
            )
        }

        return componentResponse;
    }

    return (
        <>
            <div style={{ paddingBottom: "5rem" }}>
                <form onSubmit={formik.handleSubmit}>
                <Typography variant='h5' sx={{display: "flex", justifyContent: "center", padding: "1rem"}}>{checklist.label}</Typography>
                {checklistVersion.userSelectedComponent && (
                    <div style={{padding:"1rem"}}>
                
                    <Paper sx={{padding:"1rem"}}>
                        <QuestionTitle title={`Which ${checklistVersion.userSelectedComponent.label} are you inspecting during this inspection?`} required/>
                        <Autocomplete
                            id={`userSelecteditems_${checklistVersion.userSelectedComponent.label}`}
                            multiple
                            options={userSelectedOptions(checklistVersion.userSelectedComponent).children}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    size="small"
                                    label={checklistVersion.userSelectedComponent?.label}
                                />
                            )}
                            sx={{width: "20rem"}}
                            onChange={(_, value) => handleUserSelectedCompChange(value)}
                            value={
                                formik.values.selectedComponentsResponse?.children ? formik.values.selectedComponentsResponse.children : []
                            }
                        />
                    </Paper>
                </div>  
                )}
                {checklistVersion.sections.map((section, sectionIndex) => (
                    <Fragment key={sectionIndex}>
                    {sectionVisibility[sectionIndex] && (
                        <FormSection
                            key={sectionIndex}
                            sectionIndex={sectionIndex}
                            sectionContent={section}
                            initialValues={formik.values.sectionResponses[sectionIndex]}
                            percentageToPass={1}
                            updateSectionAnswers={(sectionValues: InspectionChecklistSectionResponseDto, sectionStatus: Status) => updateSectionAnswers(sectionIndex, sectionValues, sectionStatus)}
                            questionsEditable={() => true} //Any sections can be opened in demomode
                            taggedOutComponents={taggedOutComponents}
                            setTaggedOutComponents={setTaggedOutComponents}
                            demoMode={true}
                            setMessageItem={setMessageItem}
                            selectedComponentsResponse={formik.values.selectedComponentsResponse}
                        />
                    )}
                    </Fragment>
                    
                    
                ))}
                
                <FinalSignOffSection
                    checklistName={checklist.label}
                    onCapture={onFinalSignOffCapture}
                    checklistStatus={Status.Passed}
                />
                
                </form>
            </div>
            
            <ErrorHandlingSnackbar messageItem={messageItem}/>
            <MaintenanceBottomNavigation />
        </>
    );
}

export default DemoQuestions;
