import { Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import AttractionJournalService from 'src/Services/Checklists/Inspections/AttractionJournalService'
import { guidRegex } from 'src/Utils/helperFunc'
import EditableLabel from 'src/components/EditableLabel/EditableLabel'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { JournalEntryStatus, JournalEntryStatusString, JournalEntryType, JournalEntryTypeString } from 'src/dtos/Checklists/AttractionInspections/Journal.dto'
import ServiceTaskEntry from './ServiceTaskEntry'
import { LoadingButton } from '@mui/lab'
import Attachments from 'src/components/Attachments/Attachments'

type Props = {
    id: string;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function JournalEntry({id, setMessageItem}: Props) {

    //#region queries
    const entryQuery = useQuery({
        queryKey: ["attractionJournalEntry", id],
        queryFn: async () => {
            if(guidRegex.test(id)){
                return await AttractionJournalService.GetEntry(id)
            }
            return null
        }
    })

    //Handle query errors

    useEffect(() => {
        if(entryQuery.isError){
            setMessageItem({error: entryQuery.error})
        }
    }, [entryQuery.error, entryQuery.isError, setMessageItem])
    //#endregion

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: entryQuery.data ?? AttractionJournalService.GetDefaultEntryValues(),
        onSubmit: (values) => {
            
        }
    })
    return (
    <Stack sx={{padding:"1rem"}} spacing={1}>
        <div style={{width:"100%"}}>
            <EditableLabel 
                value={formik.values.label}
                setValue={(newValue: string) => formik.setFieldValue("label", newValue)}
                defaultValue={"New Entry Title"}
                typographyProps={{
                    variant:"h5"
                }}                
            />
        </div>
        <Stack direction={"row"} spacing={1}>
            <FormControl fullWidth>
                <InputLabel id="entry-type-label">Type</InputLabel>
                <Select
                    id="type"
                    name="type"
                    labelId="entry-type-label"
                    label="Type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size={"small"}
                >
                    <MenuItem value={JournalEntryType.serviceTask}>{JournalEntryTypeString[JournalEntryType.serviceTask]}</MenuItem>
                    <MenuItem value={JournalEntryType.issueFaultRecord}>{JournalEntryTypeString[JournalEntryType.issueFaultRecord]}</MenuItem>
                    <MenuItem value={JournalEntryType.outOfService}>{JournalEntryTypeString[JournalEntryType.outOfService]}</MenuItem>

                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="entry-status-label">Status</InputLabel>
                <Select
                    id="status"
                    name="status"
                    labelId="entry-status-label"
                    label="Status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size={"small"}
                >
                    <MenuItem value={JournalEntryStatus.outstanding}>{JournalEntryStatusString[JournalEntryStatus.outstanding]}</MenuItem>
                    <MenuItem value={JournalEntryStatus.completed}>{JournalEntryStatusString[JournalEntryStatus.completed]}</MenuItem>
                </Select>
            </FormControl>        
        </Stack>

        {/* <Typography>Ride component go here.</Typography> */}
        
        {formik.values.type === JournalEntryType.serviceTask && (
            <ServiceTaskEntry
                formik={formik}
            />
        )}

        <Typography variant='h6'>Attachments</Typography>
        <Attachments currentAttachments={[]}/>

        <span style={{display:"flex", flexDirection: "row", gap:"1rem", justifyContent: "end", padding:"1rem", paddingTop:"0"}}>
            <Button>Cancel</Button>
            <LoadingButton variant='contained'>Create</LoadingButton>
        </span>

    </Stack>
  )
}

export default JournalEntry