import { Button } from '@mui/material'
import React, { useState } from 'react'
import JournalEntry from './Components/JournalEntry'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'

function Journals() {
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    return (
    <>
        <div style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
            Journals
            <Button href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}someID`}>
                Open journal
            </Button>

            <JournalEntry
                id="new"
                setMessageItem={setMessageItem}
            />
        </div>
        <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>

  )
}

export default Journals